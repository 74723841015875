@font-face {
    font-family: "Tiempos Headline";
    src: url("https://assets.propublica.org/static/v4/fonts/TiemposHeadlineWeb-Black.woff2")
            format("woff2"),
        url("https://assets.propublica.org/static/v4/fonts/TiemposHeadlineWeb-Black.woff")
            format("woff");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Graphik";
    src: url("https://assets.propublica.org/static/v4/fonts/Graphik-Regular-Web.woff2")
            format("woff2"),
        url("https://assets.propublica.org/static/v4/fonts/Graphik-Regular-Web.woff")
            format("woff");
    font-weight: normal;
    font-style: normal;
}

$colors-neutral: (
    "gray": (
        "3": #f6f6f6,
        "10": #e2e2e2,
        "20": #c6c6c6,
        "30": #ababab,
        "40": #919191,
        "50": #777777,
        "60": #5e5e5e,
        "70": #474747,
        "80": #303030,
        "90": #1b1b1b,
        "97": #0b0b0b,
    ),
    "cool": (
        "10": #dee3e6,
        "20": #bec8ce,
        "30": #9eadb6,
        "40": #84939c,
        "50": #6c7982,
        "60": #556068,
        "70": #40484f,
        "80": #2b3137,
        "90": #1b2127,
    ),
    "warm": (
        "10": #eeece7,
        "20": #cdc6b7,
        "30": #b1ab9c,
        "40": #979082,
        "50": #7d7669,
        "60": #645e51,
        "70": #4c463a,
        "80": #353025,
        "90": #201b10,
    ),
);

$colors-accent: (
    "red": (
        "10": #ffd9d1,
        "20": #fdb3a5,
        "30": #f58d7a,
        "40": #e96552,
        "50": #da352b,
        "60": #bb0b15,
        "70": #9b0000,
        "80": #7e0000,
        "90": #620000,
    ),
    "orange": (
        "10": #ffe6d0,
        "20": #ffcda3,
        "30": #fcb475,
        "40": #f49c47,
        "50": #e98406,
        "60": #c06300,
        "70": #984300,
        "80": #742300,
        "90": #540000,
    ),
    "yellow": (
        "10": #fff2d4,
        "20": #fee6aa,
        "30": #fada7f,
        "40": #f3ce50,
        "50": #eac300,
        "60": #b99800,
        "70": #8a7000,
        "80": #604900,
        "90": #3f2500,
    ),
    "olive": (
        "10": #f2f6d5,
        "20": #e3ecaa,
        "30": #d3e380,
        "40": #c0da52,
        "50": #acd105,
        "60": #7ea600,
        "70": #527d00,
        "80": #2c5500,
        "90": #1e2f00,
    ),
    "green": (
        "10": #d9f2db,
        "20": #b3e4b7,
        "30": #8bd694,
        "40": #5ec772,
        "50": #14b84f,
        "60": #00942e,
        "70": #007107,
        "80": #004f00,
        "90": #003100,
    ),
    "aqua": (
        "10": #daf3eb,
        "20": #b4e7d6,
        "30": #8cdbc3,
        "40": #5eceaf,
        "50": #00c19c,
        "60": #009a78,
        "70": #007556,
        "80": #005236,
        "90": #003118,
    ),
    "turquoise": (
        "10": #dcf2f4,
        "20": #b7e5e8,
        "30": #90d8dd,
        "40": #62cbd2,
        "50": #05bec7,
        "60": #0098a1,
        "70": #00737c,
        "80": #00505a,
        "90": #002f39,
    ),
    "blue": (
        "10": #dceef9,
        "20": #b9def3,
        "30": #92ceed,
        "40": #64bee6,
        "50": #06aee0,
        "60": #008bbb,
        "70": #006997,
        "80": #004975,
        "90": #002c54,
        "97": #012d44,
    ),
    "cobalt": (
        "10": #dde1f9,
        "20": #b9c4f3,
        "30": #93a8ed,
        "40": #678ee6,
        "50": #2074df,
        "60": #005cc3,
        "70": #0045a7,
        "80": #00308c,
        "90": #001d72,
    ),
    "purple": (
        "10": #f5e2ff,
        "20": #e9c4ff,
        "30": #dda7fe,
        "40": #cf89fd,
        "50": #bf6afb,
        "60": #9c4ad8,
        "70": #7a28b6,
        "80": #580095,
        "90": #350075,
    ),
    "fuschia": (
        "10": #f8d8f2,
        "20": #efb1e4,
        "30": #e488d6,
        "40": #d75dc8,
        "50": #c91dba,
        "60": #ad00a0,
        "70": #910086,
        "80": #75006d,
        "90": #5a0055,
    ),
    "pink": (
        "10": #fbd7e0,
        "20": #f4afc2,
        "30": #ea85a4,
        "40": #de5988,
        "50": #cf176d,
        "60": #b30057,
        "70": #970042,
        "80": #7c002e,
        "90": #61001b,
    ),
);

:root {
    --black: #000000;
    --white: #ffffff;

    @each $color, $shades in $colors-neutral {
        @each $shade, $hex in $shades {
            --#{$color}-#{$shade}: #{$hex};
        }
    }

    @each $color, $shades in $colors-accent {
        @each $shade, $hex in $shades {
            --#{$color}-#{$shade}: #{$hex};
        }
    }

    --pp-blue: #304154;
    --pp-accent-blue: #06aee0;
    --color-text-hed: var(--cool-90);
    --color-text-body: var(--cool-70);
    --color-text-supp: var(--cool-40);
    --color-body-bgd: var(--cool-10);
}

body {
    margin: 0;
    padding: 0;
    position: relative;
}

body * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pullin {
    0% {
        opacity: 0;
        transform: translateX(-50%) translateY(-45%);
    }

    100% {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
    }
}

@keyframes liftin {
    0% {
        opacity: 0;
        bottom: -1.5em;
    }

    100% {
        opacity: 1;
        bottom: 0;
    }
}

@keyframes nuhuh {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

#newsletter-roadblock {
    opacity: 0;
    animation: liftin 1s forwards;
    will-change: opacity, bottom;
}
@media (min-width: 37.5em) {
    #newsletter-roadblock {
        animation: fadein 0.65s forwards;
        will-change: opacity;
    }
}
@media (min-width: 37.5em) {
    .collapsible-content {
        opacity: 0;
        animation: pullin 1s 0.85s forwards;
        will-change: opacity, transform;
    }
}

#newsletter-roadblock {
    width: 100vw;
    bottom: 0;
    top: auto;
    background-color: var(--color-body-bgd);
    position: fixed;
    padding: 1em;
}
@media screen and (min-width: 37.5em) {
    #newsletter-roadblock {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.85);
    }
}

.collapsible-content {
    // padding-top: 3.7142857143%;
    // padding-bottom: 3.7142857143%;
}
@media screen and (min-width: 37.5em) {
    .collapsible-content {
        --color-body-bgd: var(--white);
        background-color: var(--color-body-bgd);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        margin: 0;
        min-width: 32.5em;
        max-width: 42.5em;
        padding: 3vh 5vw;
        border-radius: 0.5em;
    }
}

.content {
    font-size: 0.85em;
}

.close-btn {
    opacity: 0.5;
    text-align: right;
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    height: 16px;
    display: block;
    width: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%236C7982' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Cpath d='M1.39339828 22.6066017L22.6066017 1.39339828M22.6066017 22.6066017L1.3933983 1.39339828'/%3E%3C/g%3E%3C/svg%3E");
    background-color: transparent;
    border: none;
}
@media screen and (min-width: 37.5em) {
    .close-btn {
        right: 1em;
        top: 1em;
        height: 20px;
        width: 20px;
    }
}

.close-btn span,
.close-btn svg {
    display: none;
}

.collapsible-header.close-btn {
    display: none;
}

.hed {
    font-family: "Tiempos Headline", Georgia, Times, "Times New Roman", serif;
    font-weight: bold;
    font-size: 1.3125rem;
    line-height: 1;
    color: var(--color-text-hed);
    display: block;
    text-align: center;
    margin: 0 5%;
}
@media screen and (min-width: 37.5em) {
    .hed {
        line-height: 1.05;
        font-size: 2rem;
        margin: 0 auto 0.5em auto;
    }
}

.intro {
    font-family: Graphik, Arial, Verdana, sans-serif;
    font-size: 0.8125rem;
    color: var(--color-text-body);
    text-align: center;
    line-height: 1.4;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}
@media screen and (min-width: 37.5em) {
    .intro {
        font-size: 1.1em;
        margin-top: 1em;
        margin-bottom: 1em;
        padding-right: 0;
        padding-left: 0;
    }
}

.close-link {
    margin-bottom: 0.5em;
}
@media screen and (min-width: 37.5em) {
    .close-link {
        margin-bottom: 1em;
    }
}

.close-link a {
    color: var(--color-text-body);
    font-family: Graphik, Arial, Verdana, sans-serif;
    font-size: 0.8125rem;
    text-decoration: underline;
    display: block;
    text-align: center;
}

.close-link a:hover {
    text-decoration: none;
}

input {
    font-size: 1.2em;
}

input[type="email"],
input[type="text"],
input[type="submit"] {
    display: block;
    width: 100%;
    margin-bottom: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: center;
    border-radius: 4px;
}
@media screen and (min-width: 37.5em) {
    input[type="email"],
    input[type="text"],
    input[type="submit"] {
        padding-top: 0.75em;
        padding-bottom: 0.75em;
    }
}

input[type="email"],
input[type="text"] {
    border: 1px solid var(--cool-30);
}

input[type="submit"] {
    -webkit-appearance: none;
    background-color: var(--pp-blue);
    border: none;
    color: #fff;
    margin-bottom: 1em;
    transition: opacity 0.2s linear;
    &:focus,
    &:hover {
        background-color: var(--pp-accent-blue);
    }
}

// button:focus,
// button:hover,
// input[type="button"]:focus,
// input[type="button"]:hover,
// input[type="submit"]:focus,
// input[type="submit"]:hover {
//     background-color: #1aa2e6;
// }

input:valid + input[type="submit"],
input:valid + input[type="button"] {
    opacity: 1;
}

input[type="email"]:invalid {
    box-shadow: none;
}

input[type="email"]:invalid:not(:focus):not(:placeholder-shown) {
    box-shadow: 0 0 0 1px #da352b;
    animation: nuhuh 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-delay: 0.2s;
}

input[type="email"]:invalid:not(:focus):not(:placeholder-shown)
    + input[type="submit"] {
    background-color: #da352b;
}

.legal {
    display: inline-block;
    margin: 1em 1em 0;
    line-height: 1.4;
    text-align: center;
    font-size: 0.5rem;
    color: var(--color-text-supp);
    font-family: Graphik, Arial, Verdana, sans-serif;
}
@media screen and (min-width: 37.5em) {
    .legal {
        font-size: 0.6rem;
    }
}

.legal a {
    color: inherit;
    text-decoration: underline;
}

.legal a:hover {
    text-decoration: none;
}

.grecaptcha-badge {
    display: none;
}
